.gpt3__CTA{
    box-sizing: border-box;
    display:flex;
    flex-direction: row;
   
    align-items: center;

    width:90%;
    margin: 0 auto;
   
    background: var(--gradient-bar);
  
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10.7236px;
}

.gpt3__CTA-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gpt3__CTA p{
    font-family: var(--font-family);

font-weight: 500;
font-size: 12px;
line-height: 30px;
/* or 250% */

color: #0E0E0E;
}

.gpt3__CTA h3{
    font-family: var(--font-family);
font-weight: 800;
font-size: 24px;
line-height: 45px;
color:black;
}

.gpt3__CTA-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.gpt3__CTA-btn button{ 
    font-family: var(--font-family);
    color:#FFF;
    background-color: #000000;
    border-radius: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
  
  
    min-height: 50px;
    min-width: 150px;
    padding: 0.5rem 1rem;

    border:none;
    outline: none;
    cursor: pointer;

}

@media screen and (max-width:950px) {

    .gpt3__CTA{
        flex-direction: column;
    }

    .gpt3__CTA-btn{
        margin: 2rem 0 0;
    }
}

@media screen and (max-width:550px) {
    .gpt3__CTA{
   
     width:100%;

     margin: 2rem 0;
     justify-content: center;
     align-items: center;
     
    }

    .gpt3__CTA h3{
     font-size: 18px;
     line-height: 32px;  
     padding:0;
     margin:0; 
    }

    .gpt3__CTA-btn button{
        margin: 0 0;
        font-size: 14px;
        line-height: 28px;
    }

}