*{
    box-sizing: border-box;
    padding:0;
    margin:0;
    scroll-behavior: smooth;
}

body{
    background: var(--color-bg);
    width: 100%;
    height:100%;
}

a{
    color:unset;
    text-decoration: none;
}

.gradient__bg{
      /* ff 3.6+ */
      background:-moz-radial-gradient(circle at 3% 25%, rgba(47, 89, 153, 1) 0%, rgba(4, 12, 24, 1) 100%);

      /* safari 5.1+,chrome 10+ */
      background:-webkit-radial-gradient(circle at 3% 25%, rgba(47, 89, 153, 1) 0%, rgba(4, 12, 24, 1) 100%);
  
      /* opera 11.10+ */
      background:-o-radial-gradient(circle at 3% 25%, rgba(47, 89, 153, 1) 0%, rgba(4, 12, 24, 1) 100%);
  
      /* ie 10+ */
      background:-ms-radial-gradient(circle at 3% 25%, rgba(47, 89, 153, 1) 0%, rgba(4, 12, 24, 1) 100%);
  
      /* global 92%+ browsers support */
      background:radial-gradient(circle at 3% 25%, rgba(47, 89, 153, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

.gradient__text{
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color:transparent;

}

.section__padding{
    padding:4rem 6rem;
}

.section__margin{
    padding:4rem 6rem;
}


 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@media screen and (max-width:700px) {
    .section__padding{
        padding: 4rem;
    }

    .section__margin{
        margin:4rem;
    }
}

@media screen and (max-width:550px) {
    .section__padding{
        padding: 4rem 2rem;
    }

    .section__margin{
        margin:4rem 2rem;
    }
}

@media screen and (max-width:425px) {
    .section__padding{
        padding: 0 2rem ;
    }

    .section__margin{
        margin:0.5rem 2rem ;
    }
}


