@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.gpt3__article{
    background: var(--color-footer);
    display: flex;
    flex-direction: column;

    width: 100%;
    height:100%;
    
 
}

.gpt3__article-img{
   
 
    width:100%;
    height:100%;
    background: var(--color-footer);
}

.gpt3__article-img img{
    width:100%;
    height:100%;

}

.gpt3_article-content{

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 1rem 1.5rem;
   height:100%;

   font-family: var(--font-family);
   color:white;
}

.gpt3_article-content p{
    margin-bottom: 1rem;
    line-height: 35px;
    font-size: 11px;
    font-weight:bold;

}


.gpt3_article-content h3{
    cursor: pointer;
    font-size: 25px;
    font-weight: 800px;
    line-height: 30px;
    margin-bottom: 5rem;
}

.gpt3_article-content a{

    cursor: pointer;
    line-height: 35px;
    font-size: 12px;
    font-weight:bold;
}

@media screen and (max-width:550px) {
    .gpt3_article-content h3{
        font-size:18px;
        line-height: 25px;
    }
}
.gtp3__brand{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gtp3__brand div{
    flex:1 1;
    max-width:150px;
    min-width:120px;
    margin: 1rem;
    display:flex;
    justify-content: center;
    align-items: center;
}
.gpt3__CTA{
    box-sizing: border-box;
    display:flex;
    flex-direction: row;
   
    align-items: center;

    width:90%;
    margin: 0 auto;
   
    background: var(--gradient-bar);
  
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10.7236px;
}

.gpt3__CTA-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gpt3__CTA p{
    font-family: var(--font-family);

font-weight: 500;
font-size: 12px;
line-height: 30px;
/* or 250% */

color: #0E0E0E;
}

.gpt3__CTA h3{
    font-family: var(--font-family);
font-weight: 800;
font-size: 24px;
line-height: 45px;
color:black;
}

.gpt3__CTA-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.gpt3__CTA-btn button{ 
    font-family: var(--font-family);
    color:#FFF;
    background-color: #000000;
    border-radius: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
  
  
    min-height: 50px;
    min-width: 150px;
    padding: 0.5rem 1rem;

    border:none;
    outline: none;
    cursor: pointer;

}

@media screen and (max-width:950px) {

    .gpt3__CTA{
        flex-direction: column;
    }

    .gpt3__CTA-btn{
        margin: 2rem 0 0;
    }
}

@media screen and (max-width:550px) {
    .gpt3__CTA{
   
     width:100%;

     margin: 2rem 0;
     justify-content: center;
     align-items: center;
     
    }

    .gpt3__CTA h3{
     font-size: 18px;
     line-height: 32px;  
     padding:0;
     margin:0; 
    }

    .gpt3__CTA-btn button{
        margin: 0 0;
        font-size: 14px;
        line-height: 28px;
    }

}
.gpt3__features-container__feature{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
}

.gpt3__features-container__feature-title{
    flex:1 1;
    max-width:180px;
    margin-right:2rem;
}

.gpt3__features-container__feature-title h1{
    font-family: var(--font-family);
font-weight: 800;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 312% */

letter-spacing: -0.04em;

color: #FFFFFF;
}

.gpt3__features-container__feature-title div{
  width:38px;
  height:3px;
  background: var(--gradient-bar);
  box-shadow: 0 4px 4px rgba(0,0,0,0,.25);
  margin-bottom: 0.25rem;
}

.gpt3__features-container__feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.gpt3__features-container__feature-text p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* or 188% */
    
    
    color:var(--color-text);
}

@media screen and (max-width: 550px){
    .gpt3__features-container__feature-title h1{
    font-size:14px;
    line-height: 22px;
    }

    .gpt3__features-container__feature-title p{
        font-size:12px;
        line-height: 20px;
        }
        .gpt3__features-container__feature{
            margin: 1rem 0;
        }
}
.gpt3__navbar{
    display: flex;
    justify-content: space-between;
    align-items: centre;
    padding: 2rem 6rem;
}
.gpt3__navbar-links{
    flex:1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_container{
    display:flex;
    flex-direction: row;
}

.gpt3__navbar-links_sign{
   display:flex;
   justify-content: flex-end;
   align-items: center;


   
}

.gpt3__navbar-sign{
    display:flex;
    justify-content:flex-end;
    align-items: center;
}


.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p 
{
    color:#fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;

}

.gpt3__navbar-links_logo{
    margin-right: 2rem;

}

.gpt3__navbar-links_logo img{
   width:62.56px;
   height:16p.02px;
    
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button
{
   padding:1rem 2rem;
   color:#fff;
   font-family: var(--font-family);
   font-weight: 500;
   font-size: 18px;
   line-height: 25px;
   border:0;
   outline:none;
   cursor: pointer;

left: 1153px;
top: 55px;

background: #FF4820;
border-radius: 5px;
}

.gpt3__navbar-menu{
    margin-left:1rem;
    display:none;
    position:relative;
}

.gpt3__navbar-menu svg{
    cursor: pointer;
}

.gpt3__navbar-menu-container{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background:var(--color-footer);
    padding: 2rem;
    position: absolute;
    top:40px;
    right:0;
    margin-top:1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.gpt3__navbar-menu_container p{
    margin:1rem 0;


}

.gpt3__navbar-menu_container-links-sign{
    display:none;
}

@media screen and (max-width:1050px){
    .gpt3__navbar-links_container{
        display:none;
    }

    .gpt3__navbar-menu{
        display:flex;
    }
}

@media screen and (max-width:700px){
  .gpt3__navbar{
    padding:2rem 4rem;
  }
}

@media screen and (max-width:550px){
    .gpt3__navbar{
      padding:2rem;
    }
    .gpt3__navbar-sign{
        display:none !important;
    }

    .gpt3__navbar-menu-container{
        top:20px;
    }

    .gpt3__navbar-menu_container-links-sign{
        display:block !important;
    }

  }





.gpt3__Blog{
    display: flex;
  
    flex-direction: column;
  
}

.gpt3__blog-heading{
    width:100%;
    text-align: left;
    margin-bottom: 5rem;
}

.gpt3__blog-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__blog-container{
    display: flex;
  
    flex-direction: row;
}

.gpt3__Blog-container_GroupA{
    flex: 0.75 1;
    margin-right:2rem;
}


.gpt3__Blog-container_GroupB{
    flex:1 1;
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
    .gpt3__blog-container {
        flex-direction: column-reverse;
    }


    .gpt3__Blog-container_GroupA {
        margin: 2rem 0;
    }

    .ggpt3__Blog-container_GroupA .gpt3__article  {
       width: 48%;
    }

    .gpt3__Blog-container_GroupA .gpt3__article-img {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__Blog-container_GroupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .gpt3__Blog-container_groupA .gpt3__article  {
       width: 100%;
    }

    .gpt3__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}
.gpt3__features{
    width:100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
   
}

.gpt3__features-heading{
    flex:1 1;
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}

.gpt3__features-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
}

.gpt3__features-heading p{

    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin-top:2rem;
    color:var(--color-subtext);
    
}

.gpt3__features-container{
    flex:1.5 1;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

}

@media screen and (max-width:990px) {
    .gpt3__features{
        
        flex-direction: column;
       
    }
    .gpt3__features-heading{
        margin:0 0 2rem 0;
    }

    
}

@media screen and (max-width:550px) {
    .gpt3__features-heading h1{
        font-size: 28px;
        line-height: 38px;
    }

    
}
.gpt3__possibility{
    display: flex;
    flex-direction: row;
    width:100%;
 

}

.gpt3__possibility-image{
    margin-right:2rem;
    flex:1 1;
    display:flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__possibility-image img{
    max-width: 546px;
max-height: 607.53px;
width:100%;
height:100%;
}

.gpt3__possibility-container{
    /*top, right, bottom, left */
    flex:1 1;
    margin: auto 0 ;
    margin-left: 1rem;
    margin-right:7rem;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items:flex-start;
    
}

.gpt3__possibility-container p{
    margin-bottom: 1rem;
    text-align: left;
    font-weight: 400;
font-size: 16px;
line-height: 30px;
font-family: var(--font-family);
color:var(--color-text);
    
}

.gpt3__possibility-container h1{
    margin-bottom: 1rem 0;
    text-align: left;
    font-weight: 800;
font-size: 34px;
line-height: 45px;
font-family: var(--font-family);

    
}
.gpt3__possibility-container h4{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.gpt3__possibility-container h4:last-of-type{
    color:var(--color-subtext);
}

@media screen and (max-width:950px) {
    .gpt3__possibility{
       flex-direction: column;
     
    
    }

    .gpt3__possibility-container{
        
        margin: auto 0;
        margin-top: 2rem;
    }

    .gpt3__possibility-image{
        margin: auto 0;

    }
}

@media screen and (max-width:650px) {
    .gpt3__possibility-image{
        justify-content: center;
        align-items: center;
        width:100%;
        
    }
    
}



@media screen and (min-width: 600px) and (max-width: 900px)  and (min-height: 1000px) and (max-height: 1200px) {
    .gpt3__possibility-image img{
        height:75%;
    }
}
.gpt3__footer {
    font-family: var(--font-family);
    display: flex;
    color: #FFF;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-footer);

}

.gpt3__footer-content{
    width: 100%;
    text-align: center;
    margin-bottom: 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
   
}

.gpt3__footer-content h1{

   
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;


  
    letter-spacing: -0.04em;
    margin-bottom: 3rem;
}

.gpt3__footer-content button{
    font-family: 'Gilroy-Medium',sans-serif;
    font-size: 18px;
    line-height: 21px;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    width:218px;
    height:65px;
    cursor: pointer;
}

.gpt3__footer-container{
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
}

.gpt3__footer-container-company{
    padding: 1rem;
    margin-right:5rem;
    color:#FFF;
    display: flex;
    flex-direction: column;

 
}

.gpt3__footer-container-company p{
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    line-height: 14px;

    color: #FFFFFF;
}
.gpt3__footer-container-company img{
    width:118px;
    height:30px;
    margin-bottom: 1rem;
}

.gpt3__footer-container-links{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  
    width:100%;
}

.gpt3__footer-container-links div{
    width: 250px;
    margin: 1rem;
}

.gpt3__footer-container-links_content{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.gpt3__footer-container-links_content h4{
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 1rem;
}

.gpt3__footer-container-links_content p{
    font-size: 12px;
    line-height: 14px;
    margin: 0.5rem 0;
    cursor: pointer;
}

.gpt3_footer-copyright{
    color:#FFF;
    text-align: center;
    margin-top: 2rem;
    padding:0px;
    width: 100%;
}


@media screen and (max-width:1024px) {
    .gpt3__footer-container{
       
        flex-direction: column;
       
       
    }
}

@media screen and (max-width: 850px) {
    .gpt3__footer-content h1{
        font-size: 44px;
        line-height: 50px;
    }

    .gpt3__footer-container{
       
        flex-direction: column;
       
       
    }

    .gpt3__footer-content{
        margin-bottom: 1.5rem;
    }
    
}

@media screen and (max-width: 550px) {
    .gpt3__footer-content h1{
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-container-links_content{
       margin:1rem 0;
    }

    .gpt3__footer-content button{
       
        font-size: 14px;
        line-height: 20px;
        
    }
    
}


@media screen and (max-width: 400px) {
    .gpt3__footer-content h1{
        font-size: 26px;
        line-height: 38px;
    }

    .gpt3__footer-container-links_content{
       margin:1rem 0;
    }

    .gpt3__footer-content button{
       
        font-size: 14px;
        line-height: 20px;
        
    }
    
}

.gpt3__header{
    display:flex;
    flex-direction: row;
}

.gpt3__header-content{
    flex:1 1;
    display:flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction: column;
    margin-right:5rem;
}

.gpt3__header-content h1{
    font-family:var(--font-family);
font-weight: 800;
font-size: 62px;
line-height: 75px;
letter-spacing: -0.04em;
}

.gpt3__header-content p{
    font-family:var(--font-family);

font-weight: 400;
font-size: 20px;
line-height: 27px;

color:var(--color-text);
margin-top:1.5rem;

}

.gpt3__header-content__input{
    width:100%;
    margin: 2rem 0 1rem;
    display:flex;
    flex-direction: row;

}

.gpt3__header-content__input input{
    
    flex:2 1;
    width:100%;
   
    min-height:50px;
    font-family:var(--font-family);
    background: var(--color-footer);
   
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline:none;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
}

.gpt3__header-content__input button{
    flex:0.6 1;
    width:100%;
    min-height:50px;

    background: #FF4820;
  
  
    font-weight: 400;
    font-family:var(--font-family);
    font-size: 20px;
    line-height: 27px;
    color:#fff;
    cursor:pointer;
    outline:none;
    padding:0 1rem;
    border:none;
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
}

.gpt3__header-content__people{
    width:100%;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    margin-top:2rem;
}

.gpt3__header-content__people img{
    width: 181.79px;
height: 38px;
}

.gpt3__header-content__people p{

text-align: center;
font-family:var(--font-family);
 
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    margin:0 0 0 1rem;
    
    
    color: #FFFFFF;
}

.gpt3__header-image{
    flex:1 1;
    display:flex;
    justify-content: center;
    align-items: center;

}

.gpt3__header-image img{
    width:100%;
    height:100%;
    min-width:400px;
    min-height:400px;
}
@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }

    .gpt3__header-image img{
        
        min-width:100%;
        min-height:auto;
        object-fit: cover;
    }
}
.gpt3__whatgpt3 {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3__whatgpt3-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.gpt3__whatgpt3-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 18px;
        line-height: 21px;
    }

    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
       font-size: 12px;
       line-height: 18px;
    }

    .gpt3__features-container__feature-text p{
        font-size: 12px;
        line-height: 18px;
    }
}
*{
    box-sizing: border-box;
    padding:0;
    margin:0;
    scroll-behavior: smooth;
}

body{
    background: var(--color-bg);
    width: 100%;
    height:100%;
}

a{
    color:unset;
    text-decoration: none;
}

.gradient__bg{
      /* ff 3.6+ */

      /* safari 5.1+,chrome 10+ */
  
      /* opera 11.10+ */
  
      /* ie 10+ */
  
      /* global 92%+ browsers support */
      background:radial-gradient(circle at 3% 25%, rgba(47, 89, 153, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

.gradient__text{
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color:transparent;

}

.section__padding{
    padding:4rem 6rem;
}

.section__margin{
    padding:4rem 6rem;
}


 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@media screen and (max-width:700px) {
    .section__padding{
        padding: 4rem;
    }

    .section__margin{
        margin:4rem;
    }
}

@media screen and (max-width:550px) {
    .section__padding{
        padding: 4rem 2rem;
    }

    .section__margin{
        margin:4rem 2rem;
    }
}

@media screen and (max-width:425px) {
    .section__padding{
        padding: 0 2rem ;
    }

    .section__margin{
        margin:0.5rem 2rem ;
    }
}



:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}
