.gpt3__article{
    background: var(--color-footer);
    display: flex;
    flex-direction: column;

    width: 100%;
    height:100%;
    
 
}

.gpt3__article-img{
   
 
    width:100%;
    height:100%;
    background: var(--color-footer);
}

.gpt3__article-img img{
    width:100%;
    height:100%;

}

.gpt3_article-content{

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 1rem 1.5rem;
   height:100%;

   font-family: var(--font-family);
   color:white;
}

.gpt3_article-content p{
    margin-bottom: 1rem;
    line-height: 35px;
    font-size: 11px;
    font-weight:bold;

}


.gpt3_article-content h3{
    cursor: pointer;
    font-size: 25px;
    font-weight: 800px;
    line-height: 30px;
    margin-bottom: 5rem;
}

.gpt3_article-content a{

    cursor: pointer;
    line-height: 35px;
    font-size: 12px;
    font-weight:bold;
}

@media screen and (max-width:550px) {
    .gpt3_article-content h3{
        font-size:18px;
        line-height: 25px;
    }
}