.gpt3__possibility{
    display: flex;
    flex-direction: row;
    width:100%;
 

}

.gpt3__possibility-image{
    margin-right:2rem;
    flex:1;
    display:flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__possibility-image img{
    max-width: 546px;
max-height: 607.53px;
width:100%;
height:100%;
}

.gpt3__possibility-container{
    /*top, right, bottom, left */
    flex:1;
    margin: auto 0 ;
    margin-left: 1rem;
    margin-right:7rem;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items:flex-start;
    
}

.gpt3__possibility-container p{
    margin-bottom: 1rem;
    text-align: left;
    font-weight: 400;
font-size: 16px;
line-height: 30px;
font-family: var(--font-family);
color:var(--color-text);
    
}

.gpt3__possibility-container h1{
    margin-bottom: 1rem 0;
    text-align: left;
    font-weight: 800;
font-size: 34px;
line-height: 45px;
font-family: var(--font-family);

    
}
.gpt3__possibility-container h4{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.gpt3__possibility-container h4:last-of-type{
    color:var(--color-subtext);
}

@media screen and (max-width:950px) {
    .gpt3__possibility{
       flex-direction: column;
     
    
    }

    .gpt3__possibility-container{
        
        margin: auto 0;
        margin-top: 2rem;
    }

    .gpt3__possibility-image{
        margin: auto 0;

    }
}

@media screen and (max-width:650px) {
    .gpt3__possibility-image{
        justify-content: center;
        align-items: center;
        width:100%;
        
    }
    
}



@media screen and (600px <= width <= 900px)  and (1000px <= height <= 1200px) {
    .gpt3__possibility-image img{
        height:75%;
    }
}